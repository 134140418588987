import React from "react";

type ProductContainerProps = {
  children: React.ReactNode;
};

const ProductContainer = ({ children }: ProductContainerProps) => (
  <div className="grid grid-cols-2 gap-4 md:grid-cols-4 xl:grid-cols-6">
    {children}
  </div>
);

export default ProductContainer;
