import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

import CustomLink from "../../custom_link";
import { BiPlayCircle } from "react-icons/bi";
import { IProduct } from "../../../../hooks/use_feature";
import { ICategory } from "../../../../hooks/use_category";
import DefaultLogo from "../../default_product_logo";

type ProductItemProps = IProduct & { currentCategory?: ICategory };

const ProductItem = ({
  name,
  slug,
  logoBackgroundColor,
  image,
  currentCategory,
}: ProductItemProps) => (
  <CustomLink
    to={`/products/${slug}/`}
    className="group"
    state={currentCategory}
  >
    <div
      // style={{ backgroundColor: logoBackgroundColor }}
      className="inline-flex items-center justify-center w-full p-4 duration-200 border-2 border-opacity-25 rounded-md cursor-pointer aspect-square border-dx-theme-border group-hover:shadow-md "
    >
      {image ? (
        <div className="w-full">
          <GatsbyImage image={image} alt={name} />
        </div>
      ) : (
        <DefaultLogo />
      )}
    </div>
    <div className="flex flex-row items-center justify-between pt-2">
      <div className="text-sm font-medium group-hover:text-dx-theme-link">
        {name}
      </div>
      <BiPlayCircle
        size={24}
        className="min-w-[24px] text-dx-theme-mute group-hover:text-dx-theme-link"
      />
    </div>
  </CustomLink>
);

export default ProductItem;
