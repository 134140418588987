import React from "react";
import useCategory from "../../hooks/use_category";
import HomeCategoryItem from "./home_category_item";

const HomeCategorySection = () => {
  const categories = useCategory();
  return (
    <section className="pt-8 sm:pt-16 " id="categories">
      <h2 className="mb-4 text-2xl font-bold">カテゴリー</h2>
      <div className="grid grid-cols-2 gap-2 sm:gap-4 xl:grid-cols-3 2xl:grid-cols-4">
        {categories &&
          categories.map((category) => (
            <HomeCategoryItem key={category.id} category={category} />
          ))}
      </div>
      {/* {JSON.stringify(featureProducts)} */}
    </section>
  );
};

export default HomeCategorySection;
