import * as React from "react";
import Layout from "../layout/";
import SEO from "../components/seo";
import HomeCategorySection from "../components/home/home_category_section";
import HomeBannerSection from "../components/home/home_banner_section";
import HomeFeatureProductsSection from "../components/home/home_feature_products_section";

const IndexPage = () => {
  return (
    <Layout>
      <HomeBannerSection />
      <HomeFeatureProductsSection />
      <HomeCategorySection />
    </Layout>
  );
};

export const Head = ({ location }) => {
  return (
    <SEO
      title={"DXies｜ビジネスをDXする世界のクラウド製品を一覧掲載"}
      description={"DXiesでは、デジタルトランスフォーメーションに欠かせない世界のクラウド製品をカテゴリーごとに掲載。貴重な日本語での情報をお届けします。"}
      path={location.pathname}
    />
  );
};

export default IndexPage;
