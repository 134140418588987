import React from "react";
import useFeatureProducts from "../../hooks/use_feature";
import ProductContainer from "../common/product/product_container";
import ProductItem from "../common/product/product_item";

const HomeFeatureProductsSection = () => {
  const featureProducts = useFeatureProducts();
  return (
    <section className="pt-8 sm:pt-16">
      <h2 className="mb-4 text-2xl font-bold">ピックアップ</h2>
      <ProductContainer>
        {featureProducts &&
          featureProducts.map((product) => (
            <ProductItem key={product.id} {...product} />
          ))}
      </ProductContainer>
      {/* {JSON.stringify(featureProducts)} */}
    </section>
  );
};

export default HomeFeatureProductsSection;
