import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { StaticImage } from "gatsby-plugin-image";

const HomeBannerSection = () => (
  <section className="w-full">
    <div className="flex items-center justify-center aspect-w-8 aspect-h-3">
      <StaticImage
        src={"../../images/home/banner.jpg"}
        aspectRatio={8 / 3}
        layout="constrained"
        className="w-full"
        transformOptions={{ cropFocus: "center", fit: "cover" }}
        alt={"home"}
      />
    </div>
  </section>
);

export default HomeBannerSection;
