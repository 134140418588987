import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { ICategory } from "../../hooks/use_category";

type HomeCategoryItemProps = {
  category: ICategory;
};

const HomeCategoryItem = ({ category }: HomeCategoryItemProps) => (
  <Link
    to={`/${category.slug}/`}
    className="inline-flex h-11 cursor-pointer items-center justify-between rounded-md border border-dx-theme-divier p-2  py-1.5 px-2 sm:px-4 text-[13px] duration-200 hover:border-transparent hover:border-opacity-0 hover:bg-dx-theme-item-highlight hover:shadow-md "
  >
    <span className="pr-2">{category.name}</span>
    <div className="flex h-full min-w-[20px] max-w-[20px] items-center">
      {category?.icon?.gatsbyImageData && (
        <GatsbyImage alt={""} image={category.icon.gatsbyImageData} />
      )}
    </div>
  </Link>
);

export default HomeCategoryItem;
